<template>
  <div class="main">
    <img
      :src="require('@/assets/img/minion-404.jpg')"
      alt="Minion 404"
      style="height: 40%"
    />
    <div class="mx-3">
      <h2 class="mt-3">Oh sorry! We couldn't find the page</h2>
    </div>
    <h5 class="px-4 my-2">
      We seem not to find the page you're looking for. Kindly head back to our
      home page.
    </h5>
    <router-link
      to="/"
      class="btn btn-primary rounded-pill px-5 mx-2 mt-3 py-2"
    >
      <i class="bi bi-arrow-left"></i> GO HOME</router-link
    >
  </div>
</template>

<style scoped lang="scss">
.main {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .circle {
    background: #0d6efd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 15rem;
    height: 15rem;
  }

  h1 {
    color: #fff;
    font-size: 5rem;
    font-weight: bold;
  }
  h2 {
    color: #000;
    font-weight: 600;
  }
  h5 {
    color: rgb(182, 175, 175);
    font-size: 1rem;
  }
}
</style>
